<template>
	<div>
		<div class="row box">
			<div class="col-12">
				<h3 class="mb-4">{{ $t('monte.modeles_contrats_liaison_etalon') }}</h3>

				<CustomTable
					id_table="season_distribute"
					ref="table_models"
					:items="models"
					:busy="table_busy_models"
					primaryKey="model_id"
					:columsAdd="modelsAddColumns"
					:externSlotColumns="['default_ca']"
					:hrefsRoutes="config_table_hrefs"
				>
					<template v-slot:[`custom-slot-cell(same_as_parent)`]="{ data }">
						<font-awesome-icon class="text-success" v-if="data.same_as_parent" :icon="['fal', 'check-circle']" />
						<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
					</template>
					<template v-slot:[`custom-slot-cell(default_ca)`]="{ data }">
						<b-form-checkbox
							class="custom-control custom-checkbox float-right mr-2"
							v-model="data.default_ca"
							@input="switchDefaultModelCa(data)"
							name="check-default-ca" 
							switch
						>
						</b-form-checkbox>
					</template>
				</CustomTable>
			</div>
		</div>

		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.add_stallion_to_model") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<form @submit.prevent="checkForm" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>
						
						<div class="col-12">
							<div class="form-group">
								<label for="horse_id">{{ $t('horse.horse') }}</label>
								<e-select
									v-model="horse"
									track-by="horse_id"
									label="horse_nom"
									:placeholder="labelTitleHorse"
									:selectedLabel="selectedLabel"
									:options="horses"
									:searchable="true"
									:multiple="true"
									:allow-empty="false"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('horse') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.horse_nom }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
							<template v-if="horse != null">
								<!-- <div class="form-group" v-if="models_to_add.length > 0">
									<label for="horse_id">{{ $t('monte.modele_contrat') }}</label>
									<e-select
										v-model="model"
										track-by="model_id"
										label="model_label"
										:placeholder="labelTitleModele"
										:selectedLabel="selectedLabel"
										:options="models_to_add"
										:searchable="true"
										:allow-empty="false"
										:show-labels="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
								<div class="form-group" v-else> -->
									<p>{{ $t('monte.no_modele_exist_so_create') }}</p>
								<!-- </div> -->
							</template>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button block pill variant="primary" @click="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import ModelMixin from "@/mixins/Model.js"

	export default {
		name: "ModeleDistribute",
		props: ['season_id', 'model_id', 'modelable_id'],
		mixins: [Navigation, ModelMixin],
		data () {
			return {
				table_busy_models: false,
				distribute_busy: false,
				form_message: '',
				processing: false,
				modal_loaded: false,
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					}
				},
				contract_config: [],
				// model: null,
				horse: null,
				horses: [],
				all_horses: [],
				models: [],
				errors: [],
				models_to_add: [],
				labelTitleModele: this.getTrad("monte.rechercher_modele"),
				labelTitleHorse: this.getTrad("horse.rechercher_cheval"),
				selectedLabel: this.getTrad("global.selected_label"),
				extern_slot_columns: ['same_as_parent'],
				events_tab: {
					"TableAction::goToExecDistributeSeasonModel": this.execDistributeSeasonModel,
					"TableAction::goToAddStallionToModeleParent": this.addStallionToModeleParent,

				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.table_busy_models = true
				this.models = await this.getStallionModeleFromGlobal(this.model_id)
				this.table_busy_models = false
			},

			async execDistributeSeasonModel(model_ids){
				if (this.distribute_busy == true){
					return;
				}
				this.distribute_busy = true;
				let result = await this.saveDistributionSeasonModel(model_ids, this.model_id)
				if(result == null)
				{
					this.failureToast("toast.distribute_modele_failed")
				}
				else
				{
					this.successToast("toast.distribute_modele_success")
					this.$refs.table_models.unselectAll()
					this.init_component()
				}
				this.distribute_busy = false;
			},

			async addStallionToModeleParent()
			{
				this.modal_loaded = false
				this.$refs["modal"].show()
				this.horses = []
				this.horse = null
				this.contract_config = []
				this.all_horses = await this.getStallionSeason(this.season_id, this.model_id)

				if(this.all_horses != null)
				{
					Object.keys(this.all_horses).forEach(horse => {
						this.horses.push(this.all_horses[horse].stallion)
					})
				}

				this.modal_loaded = true
			},

			async checkForm()
			{
				this.errors = []
				if(this.processing == false)
				{
					this.processing = true

					if(!this.horse)
					{
						this.errors.push("horse")
					}

					if(this.errors.length > 0)
					{
						this.processing = false
						return false
					}

					let result = await this.saveStallionToModeleParent(this.model_id, this.contract_config)
					if(result != null)
					{
						this.horses = []
						this.horse = null
						this.contract_config = []
						this.successToast()
						this.init_component()
						this.$refs["modal"].hide()
					}
					else
					{
						this.failureToast()
					}

					this.processing = false
				}
			},

			switchDefaultModelCa(model) {
				const to_switch = this.models.find(m => m.model_id == model.model_id)
	
				// Si l'utilisateur veut désactiver le modèle
				if(to_switch.default_ca === true) {
					this.failureToast('toast.contract_ca_default_model')
					this.$refs.table_models.refreshTable()
					return
				}

				to_switch.default_ca = !to_switch.default_ca
				this.setDefaultCaModelContract(model.model_id, model.modelable_id)

				this.$refs.table_models.refreshTable()
			}
		},
		computed: {
			hasDedicatedCa() {
                return this.$store.state.userAccess.hasDedicatedClientArea
			},
			modelsAddColumns() {
				if(!this.hasDedicatedCa) return []
				return ['default_ca']
			}
		},
		watch: {
			horse(val, prec){
				this.contract_config = []

				if(val != null)
				{

					for (let i = 0; i < val.length; i++) {
						this.contract_config.push(this.all_horses[val[i].horse_id].contract_config)
					}
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			HeaderTab : () => import('@/components/HeaderTab'),
		}
	}
</script>